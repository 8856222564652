require('../styles/material-text-field.scss')

const setActive = (el, active) => {
  const formField = el.parentNode.parentNode
  if (active) {
    formField.classList.add('form-field--is-active')
  } else {
    formField.classList.remove('form-field--is-active')
    el.value === '' ? 
      formField.classList.remove('form-field--is-filled') : 
      formField.classList.add('form-field--is-filled')
  }
}

module.exports.initMaterialFields = () => {
  const fields = document.querySelectorAll('.form-field__input, .form-field__textarea')
  for (const el of fields) {
    setActive(el, false)
    el.onblur = () => {
      setActive(el, false)
    }
    el.onfocus = () => {
      setActive(el, true)
    }
  }

  [].forEach.call(
    document.querySelectorAll('.form-field__label'),
    (el) => {
      el.onclick = () => {
        setActive(el, true)
        el.nextElementSibling.focus();
        el.nextElementSibling.select();
      }
    }
  )
}

