const content = require('../html/work.html')
const {addRoutePostRender, registerRoute} = require('./router')
const {createElementFromHTML} = require('./utils')
const {loadWorkList} = require('./image-loader')

function workPage() {
	const element =  createElementFromHTML(content)
	loadWorkList(element)
	return element
}

registerRoute('work', '/work', workPage)

registerRoute('work', '/showreel', workPage)

addRoutePostRender('/work', function() {
	const workSection = document.querySelector('#work-page .works')
	workSection.scrollIntoView({
		block: 'start',
		behavior: 'smooth'
	})
})