const content = require('../html/contact.html')
const {registerRoute, addRoutePostRender, overrideRouteTransition, navigateTo} = require('./router')
const {createElementFromHTML} = require('./utils')
const axios = require('axios')
const anime = require('animejs').default
const ANIM_DURATION = 1200
const {initMaterialFields} = require('./material-text-field')
const {beginOverlay, endOverlay} = require('./overlay-helper')

function setupForm(element) {
	const form = element.querySelector('#contact-form')
	
	const nameField = form.querySelector('input[name=name]')
	const emailField = form.querySelector('input[name=email]')
	const phoneField = form.querySelector('input[name=phone]')
	const detailsField = form.querySelector('input[name=details]')

	form.onsubmit = () => {
		console.log('submitting contact form')
		const postUrl = form.action || '/api/contact';
		axios.post(postUrl, {
		    name: nameField.value,
		    email: emailField.value,
		    phone: phoneField.value,
		    details: detailsField.value,
		})
		.then(() => {
			alert('Successfully contacted us')
			form.reset();
		})
		.catch(() => {
			alert('Failed to contact us')
		})
		return false;
	}
}

registerRoute('contact', '/contact', function() {
	const contentEle = createElementFromHTML(content)
	setupForm(contentEle)
	return contentEle
})

addRoutePostRender('/contact', function(element) {
	// console.log('post-render contact')

	const closeBtn = element.querySelector('.close')
	if (closeBtn) {
		closeBtn.onclick = () => {
			if (window.history.state.position === 0) {
				navigateTo('/')
			} else {
				window.history.back()
			}
			return false;
		}
	}
	anime({
		targets: element.querySelector('#contact-form').children,
		easing: 'easeInOutExpo',
		translateY: ['60%', '0%'],
		opacity: [0, 1],
		duration: 300,
		delay: anime.stagger(150, {start: ANIM_DURATION}),
		complete: initMaterialFields
	})
})

overrideRouteTransition('/contact', function(processCurrent, processPrevious, isForward) {
	const tl = anime.timeline({
		duration: ANIM_DURATION,
		easing: 'easeInOutExpo',
		autoplay: false,
		direction: isForward ? 'normal' : 'reverse'
	})
	processCurrent()
	if (!isForward) {
		window.scrollTo(0, 0)
	}

	const darkOverlay = document.querySelector('#dark-overlay')
	const contactOverlay = document.querySelector('#contact-overlay')
	const prevContent = document.getElementById('main-content').firstElementChild
	const mainFooter = document.getElementById('main-footer')

	tl
	.add({
		targets: darkOverlay,
		translateY: ['0%', '-100%'],
		opacity: [0, 0.8],
		begin: () => {
			darkOverlay.style.display = 'block'
			if (isForward) {
				beginOverlay()
			} else {
				endOverlay()
			}
		},
		complete: () => {
			darkOverlay.style.display = null
		}
	}, 0)
	.add({
		targets: contactOverlay,
		translateY: ['100%', '0%'],
		begin: () => {
			contactOverlay.style.display = 'block'
		},
	}, 0)
	.add({
		targets: prevContent,
		marginTop: [0, -800],
		begin: () => {
			mainFooter.style.paddingBottom = '800px'
		},
		complete: () => {
			mainFooter.style.paddingBottom = null
			processPrevious()
		}
	}, 0)

	return tl
})