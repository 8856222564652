const path = require('path')

module.exports.getCoords = (element) => { // crossbrowser version
    const bodyRect = document.body.getBoundingClientRect(),
    elemRect = element.getBoundingClientRect(),
    offset   = elemRect.top - bodyRect.top;
    return { top: offset };
}

module.exports.createElementFromHTML = function(htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild; 
}

module.exports.getConfigPathRelativeFrom = function(configRefPath, fromPath) {
  const pathToConfig = path.resolve(__dirname, '..')
  return path.relative(fromPath, path.resolve(pathToConfig, configRefPath))
}