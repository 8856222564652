require('../images/favicon.ico')
require('../styles/styles.scss')
require('aos/dist/aos.css')
require('./contact-icon')
require('./contact-page')
require('./work-page')
require('./main-page')
require('./info-page')
require('./brand-page')
const {initMenu} = require('./menu')
const {initRouter} = require('./router')
// require('./cat-video')

initMenu()
initRouter()

const AOS = require('aos')
AOS.init({
	mirror: false
})
