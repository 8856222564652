const anime = require('animejs').default

let menuOverlay

function init() {
	menuOverlay = document.getElementById('menu-overlay')
	const menuButton = document.querySelector(".header .menu-btn")
	menuButton.onclick = openMenu

	const closeMenuButton = document.querySelector("#menu-overlay .close")
	if (closeMenuButton) {
		closeMenuButton.onclick = closeMenu
	}
}

function openMenu() {
	if (menuOverlay.classList.contains('show')) {
		return
	}
	anime({
		targets: menuOverlay,
		translateY: ['-100%', 0],
		begin: function() {
		    menuOverlay.classList.add('show')
	  	}
	})
}

function closeMenu() {
	console.log('closeMenu')
	if (!menuOverlay.classList.contains('show')) {
		return
	}
	anime({
		targets: menuOverlay,
		translateY: [0, '-100%'],
		complete: function() {
			menuOverlay.classList.remove('show')
		}
	})
}


module.exports.initMenu = init
module.exports.openMenu = openMenu
module.exports.closeMenu = closeMenu