const content = require('../html/main.html')
const {registerRoute,addRoutePostRender} = require('./router')
const {createElementFromHTML} = require('./utils')
const Rellax = require('rellax')
const isSafari =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

registerRoute('main', '/', function() {
	const element = createElementFromHTML(content)
	if (isSafari) {
		// replace the cat video with image
		const catVideo = element.querySelector('#cat-vid')
		const catImg = new Image()
		catImg.src = require('../images/cat-ios.png')
		catImg.classList.add('img-responsive')
		catVideo.parentNode.replaceChild(catImg, catVideo)
	}
	
	return element
})

addRoutePostRender('/', function(element) {
	const Rellax = require('rellax')

	addImagesOnLoad('.image-grid .column1')
	addImagesOnLoad('.image-grid .column2')

	// const rellaxColumn1 = new Rellax('.image-grid .column1') 
	// const rellaxColumn2 = new Rellax('.image-grid .column2') 
	window.scrollTo(0, 0)
})


function addImagesOnLoad(columnSelector) {
	const columnImages = document.querySelectorAll(columnSelector + ' img')
	let count = 0
	const imagesLength = columnImages.length
	console.log('imagesLength', imagesLength)
	for (const img of columnImages) {
		img.onload = () => {
			count++
			if (count == imagesLength) {
				new Rellax(columnSelector) 
			}
			this.onload = null
		}
	}
}