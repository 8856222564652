const anime = require('animejs').default
const mainContent = document.getElementById('main-content')

function loadImages(element) {
	element.querySelectorAll("img").forEach(img => {
		loadImage(img, function(img, next) {
			anime({
			  targets: img,
			  opacity: [0, 1],
			  easing: 'easeInOutQuad',
			  duration: 1500,
			  complete: function() {
			  	next()
			  }
			});
		})
	})
}

function loadImage(img, onLoaded) {
	const dataSrc = img.getAttribute('data-src')
	if (!dataSrc || img.getAttribute('data-src-status') !== null) {
		return
	}
	img.setAttribute('data-src-status', 'loading')
	const initialHeight = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) * 0.3
	img.style.height = initialHeight + 'px'
	// console.log('initial height: ', initialHeight, img.style.height)

	const downloadingImage = new Image()
	downloadingImage.onload = function() {
		let imageShown = false
		const next = function() {
			img.setAttribute('data-src-status', 'loaded')
			img.src = downloadingImage.src
			img.style.height = null
			imageShown = true
		}

		if (onLoaded) {
			onLoaded(img, next)
		}
		if (!imageShown) {
			next()
		}
	}
	downloadingImage.src = dataSrc
}

function loadWorkList(element) {
	const images = element.querySelectorAll(".works img")
	const tl = anime.timeline({
	  easing: 'easeInOutQuad',
	  duration: 300,
	  autoplay: false,
	  begin: function() {
	  	console.log('img loaded animate')
	  }
	})
	for (let i = 0; i < images.length; i += 2) {
		const left = images[i]
		const right = images[i + 1]
		const targets = [left]
		if (right) {
			targets.push(right)
		}

		((t) => {
			tl.add({
				targets: t,
	  			opacity: [0, 1],
			})
		})(targets)
	}
	tl.play()
}

module.exports.loadImages = loadImages
module.exports.loadWorkList = loadWorkList