const {closeMenu} = require('./menu')
const anime = require('animejs').default

const routes = {}
const mainContent = document.getElementById('main-content')
const darkOverlay = document.querySelector('#dark-overlay')
const ANIM_DURATION = 1000
let prevRoute = false
let stackPosition = 0

function defaultAnimation(processCurrent, processPrevious, isForward) {
	const tl = anime.timeline({
	  targets: darkOverlay,
	  easing: 'easeOutExpo',
	  duration: ANIM_DURATION,
	  autoplay: false,
	  direction: isForward ? 'normal' : 'reverse'
	})
	tl
	.add({
		opacity: [0, 1],
		begin: () => {
			darkOverlay.style.display = 'block'
		},
		complete: () => {
			window.scrollTo(0, 0)
			processCurrent()
			processPrevious()
		}
	})
	.add({
		opacity: [1, 0],
		complete: () => {
			darkOverlay.style.display = null
		}
	})
	return tl
}

function processCurrent(path, initialStyle={}) {
	const route = routes[path]
    const element = route.render()
    Object.assign(element.style, initialStyle)
    mainContent.appendChild(element)
    document.body.classList.add(route.name)
}

function processPrevious(path, prevPath) {
	const route = routes[path]
	const prevRoute = routes[prevPath]

    if (mainContent.childElementCount === 2) {
    	mainContent.replaceChild(mainContent.children[1], mainContent.firstElementChild)
    }
    if (prevPath && prevRoute.name != route.name) {
	    document.body.classList.remove(prevRoute.name)
    }

    if (route.postRender) {
    	route.postRender(mainContent.firstElementChild)
    }
}

function handlePath(path) {
	console.log('handling ' + path)
    if (!routes[path]) {
    	console.log('path not found ignoring')
    	return
    }

	const route = routes[path]
	const prevPath = prevRoute.path

	if (prevPath === path) {
		closeMenu()
		return
	}

	const proceedCurrent = (initialStyle) => processCurrent(path, initialStyle)
	const proceedPrevious = () => processPrevious(path, prevPath)
	let anim
	// precedence: prevRoute > currentRoute > default
	if (prevRoute.transitionAnim) {
		anim = prevRoute.transitionAnim(proceedCurrent, proceedPrevious, false)
	} else if (route.transitionAnim) {
		anim = route.transitionAnim(proceedCurrent, proceedPrevious, true)
	} else {
		anim = defaultAnimation(proceedCurrent, proceedPrevious, true)
	}
	if (prevRoute.cleanup) {
		prevRoute.cleanup()
	}

	closeMenu()
	anim.play()

	prevRoute = route
}

function navigateTo(path) {
	window.history.pushState({
    	path,
    	position: ++stackPosition
    }, '', path);
    handlePath(path)
}

function init() {
	const levelsOfCheck = 3
	document.addEventListener('click',function(e){
		let target = e.target
		let level = 0
	    while (!target || target.tagName !== 'A'){
	        if (levelsOfCheck === level) {
	        	break
	        }
	        target = target.parentNode
	        level += 1
	    }
	    
	    if (!target || !target.pathname || target.hostname != window.location.hostname || target.hash.length > 0 || e.target.target) {
	    	return true
	    }

	    e.preventDefault()

	    const anchor = target
	    const path = anchor.pathname
	    
	    navigateTo(path)
	    return false
	})
	
	window.history.replaceState({
		path: window.location.pathname,
		position: stackPosition
	}, '')
	darkOverlay.style.display = 'block'
	handlePath(window.location.pathname)

	window.addEventListener('popstate', event => {
	  console.log(event)
	  stackPosition = event.state.position
	  handlePath(document.location.pathname)
	}, false);
}

function registerRoute(name, path, renderFn) {
	routes[path] = {
		render: renderFn,
		name,
		path,
	}
}

function addRoutePostRender(path, handlerFn) {
	const route = routes[path]
	if (route) {
		route.postRender = handlerFn
	} else {
		routes[path] = {
			postRender: handlerFn
		}
	}
}

function overrideRouteTransition(path, anim) {
	const route = routes[path]
	if (route) {
		route.transitionAnim = anim
	} else {
		routes[path] = {
			transitionAnim: anim
		}
	}
}

function addRouteCleanup(path, cleanupFn) {
	const route = routes[path]
	if (route) {
		route.cleanup = cleanupFn
	} else {
		routes[path] = {
			cleanup: cleanupFn
		}
	}
}

module.exports.initRouter = init
module.exports.registerRoute = registerRoute
module.exports.addRoutePostRender = addRoutePostRender
module.exports.overrideRouteTransition = overrideRouteTransition
module.exports.navigateTo = navigateTo
module.exports.addRouteCleanup = addRouteCleanup