const {registerRoute, addRouteCleanup, addRoutePostRender} = require('./router')
const {createElementFromHTML} = require('./utils')
const {loadWorkList} = require('./image-loader')
const modernizr = require("modernizr")

function createRoute(key) {
	const content = require(`../html/info-${key}.html`)
	const path = `/info/${key}`
	registerRoute('info', path, function() {
		const element = createElementFromHTML(content)
		const descriptionSection = element.querySelector('.description-section')
		const highlight = element.querySelector('.highlight')
		const playButton = highlight.querySelector('.play-btn')
		const closeButton = highlight.querySelector('.close-btn')
		const plusSign = highlight.querySelector('.plus-sign')
		const video = highlight.querySelector('video')
		plusSign.onclick = function() {
			descriptionSection.scrollIntoView({
				behavior: "smooth", 
				block: "start"
			})
		}
		if (playButton) {
			playButton.onclick = function() {
				highlight.classList.add('show-video')
				video.play()
			}	
		}
		if (closeButton) {
			closeButton.onclick = function() {
				highlight.classList.remove('show-video')
				video.pause()
			}
		}
		
		loadWorkList(element)
		return element
	})

	modernizr.on('cssvhunit', function(result) {
		if (result) {
			// console.log('viewport unit supported')
			return
		}

		const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) + 'px'
		for (const video of element.querySelectorAll('.videos-section .video-box video')) {
			video.style.maxHeight = viewportHeight
		}
	})
}


createRoute('nabei-cat')

createRoute('changi-group')

createRoute('aia')

createRoute('gojek')

createRoute('mckinsey')

createRoute('manulife')

createRoute('uniqlo')

createRoute('starhub')

createRoute('cpf-medishield-life')

createRoute('lyfe')

createRoute('essilor')

createRoute('oya')

createRoute('anchor-sp')

createRoute('cisco-security')

createRoute('anchor-showreel')
