const anime = require('animejs').default

document.querySelectorAll('.contact-subtitle').forEach(
  (contactSubtitle) => {
	const contactIcon = contactSubtitle.parentElement.querySelector('.contact-icon')
	if (!contactIcon) return
	
	let mouseEntered = false
	let animating = false

	const animation = anime({
	  targets: contactIcon,
	  keyframes: [
	  	{opacity: 0, rotate: 45},
	    {opacity: 1, rotate: 0},
	  ],
	  duration: 500,
	  easing: 'easeOutElastic(1, .8)',
	  begin: () => animating = true,
	  complete: () => animating = false, 
	})

	contactSubtitle.onmouseover = () => {
		if (mouseEntered) return

		// console.log('onmouseover')
		if (animating) {
			animation.reverse()
		} else {
			animation.direction = 'normal'
			animation.play()
		}
		// console.log('direction:', animation.direction)
		mouseEntered = true
	}

	contactSubtitle.onmouseleave = () => {
		if (!mouseEntered) return

		// console.log('onmouseleave')
		if (animating) {
			animation.reverse()
		} else {
			animation.direction = 'reverse'
			animation.play()
		}
		// console.log('direction:', animation.direction)
		mouseEntered = false
	}
  }
)
